<template>
  <div class="add-client">
    <div class="add-client__content" v-loading="loading">
      <div>
        <div class="add-client__title" v-text="title">
        </div>
        <Selector
          ref="clientType"
          class="form-input add-client__selector add-client__input"
          required
          :title="'Тип контрагента*'"
          :options="Object.keys(legalForms).map((key) => ({ id: key, name: legalForms[key] }))"
          :select-fitrst-item="false"
          :default-select-item-id="form.legalForm"
          @handleChange="handleChangeLegalForm"
        />
        <div
          class="form-input"
          :class="[{'form-input--full' : form.inn},{'form-input--error' : errors.inn}]"
        >
          <input v-model="form.inn" type="text" required>
          <span class="form-input__label">ИНН*</span>
          <template v-if="errors.inn && errors.inn.length">
            <div
              v-for="error in errors.inn"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
          </template>
        </div>
        <div
          class="form-input"
          :class="[
            {'form-input--full' : form.name },
            {'form-input--error' : errors.name }
          ]"
        >
          <textarea v-model="form.name " rows="5" />
          <span class="form-input__label">Наименование*</span>
          <template v-if="errors.name && errors.name.length">
            <div
              v-for="error in errors.name"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
          </template>
        </div>

        <div
          v-if="isInternationalJuridicalLegalForm || isInternationalPhysicalLegalForm"
          class="form-input"
          :class="[
            {'form-input--full' : form.OksmNumber },
            {'form-input--error' : errors.OksmNumber }
          ]"
        >
          <textarea v-model="form.OksmNumber" rows="5" />
          <span class="form-input__label">Код страны регистрации*</span>
          <template v-if="errors.OksmNumber && errors.OksmNumber.length">
            <div
              v-for="error in errors.OksmNumber"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
          </template>
        </div>
        <div
          v-if="isInternationalPhysicalLegalForm"
          class="form-input"
          :class="[
            {'form-input--full' : form.mobilePhone },
            {'form-input--error' : errors.mobilePhone }
          ]"
        >
          <textarea v-model="form.mobilePhone " rows="5" />
          <span class="form-input__label">
            Номер телефона
            {{ isRequiredMobilePhone ? '*' : '' }}
          </span>
          <template v-if="errors.mobilePhone && errors.mobilePhone.length">
            <div
              v-for="error in errors.mobilePhone"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
          </template>
        </div>
        <div
          v-if="isInternationalPhysicalLegalForm"
          class="form-input"
          :class="[
            {'form-input--full' : form.EpayNumber },
            {'form-input--error' : errors.EpayNumber }
          ]"
        >
          <textarea v-model="form.EpayNumber " rows="5" />
          <span class="form-input__label">
            Номер электронного средства платежа
            {{ isRequiredEpayNumber ? '*' : '' }}
          </span>
          <template v-if="errors.EpayNumber && errors.EpayNumber.length">
            <div
              v-for="error in errors.EpayNumber"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
          </template>
        </div>
        <div
          v-if="isInternationalJuridicalLegalForm"
          class="form-input"
          :class="[
            {'form-input--full' : form.RegNumber },
            {'form-input--error' : errors.RegNumber }
          ]"
        >
          <textarea v-model="form.RegNumber " rows="5" />
          <span class="form-input__label">Регистрационный номер либо его аналог</span>
          <template v-if="errors.RegNumber && errors.RegNumber.length">
            <div
              v-for="error in errors.RegNumber"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
          </template>
        </div>
      </div>
      <div class="add-client__group-btns">
        <template v-if="errors.global && errors.global.length">
            <div
              v-for="error in errors.global"
              :key="error"
              class="form-input__error"
             >{{ error }}</div>
        </template>
        <div class="add-client__btns-wrapper">
          <ButtonElement
            @click-button="isClientForInitialContract ? createInitialClient() : createDirectClient()"
            text="Сохранить"
            class="add-client__save-btn"
            :disabled="isDisabledSaveBtn"
          />
          <ButtonElement
            @click-button="closeDialog"
            class="add-client__cancel-btn button--empty"
            :text="'Отменить'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import formValidate from "@/mixin/formValidate";
import Selector from "@/components/sort/Selector";
import numberWithSpaces from '@/mixin/numberWithSpaces'
import { CreateModesType, LegalForms, LegalFormsKeys, Tabs } from "@/utils/clients";
import { mapMutations } from "vuex"
import { clearQuery } from "@/utils/helpers"
import { mapState } from "vuex"

const errors = () => ({
  name: null,
  inn: null,
  global: []
})

const form = () => ({
  legalForm: "",
  inn: "",
  name: "",
  RegNumber: "",
  OksmNumber: "",
  mobilePhone: "",
  EpayNumber: "",
})

export default {
  name: 'AddClient',
  components: {
    ButtonElement,
    Selector,
  },
  mixins: [formValidate, numberWithSpaces],
  data() {
    return {
      legalForms: LegalForms,
      form: form(),
      errors: errors(),
      loading: false,
      clickOutsideModal: true,
    }
  },
  computed: {
    ...mapState('Clients', ['clients']),
    isDisabledSaveBtn() {
      if (this.isInternationalJuridicalLegalForm) {
        return !(this.form.legalForm && this.form.inn && this.form.name && this.form.OksmNumber.length)
      }

      if (this.isInternationalPhysicalLegalForm) {
        return !(this.form.legalForm && this.form.inn && this.form.name && (!!this.form.mobilePhone.length || !!this.form.EpayNumber.length) && this.form.OksmNumber.length)
      }

      return !(this.form.legalForm && this.form.inn && this.form.name)
    },
    isRequiredEpayNumber() {
      return this.isInternationalPhysicalLegalForm && !this.form.mobilePhone.length
    },
    isRequiredMobilePhone() {
      return this.isInternationalPhysicalLegalForm && !this.form.EpayNumber.length
    },
    isInternationalPhysicalLegalForm() {
      return this.form.legalForm === LegalFormsKeys.INTERNATIONAL_PHYSICAL_PERSON 
    },
    isInternationalJuridicalLegalForm() {
      return this.form.legalForm === LegalFormsKeys.INTERNATIONAL_JURIDICAL_PERSON
    },
    isClientForInitialContract() {
      return this.$route.query.view === Tabs.INITIAL_CONTRACTS
    },
    getFormName() {
      return this.$route.query.formName
    },
    title() {
      return this.getFormName === 'clientId' 
        ? 'Добавить рекламодателя' 
        : this.getFormName === 'contractorId'
          ? 'Добавить исполнителя'
          : 'Добавить заказчика'
    }
  },
  mounted() {
    this.prepareForm()
  },
  methods: {
    ...mapMutations('InitialContracts', {
      setInitialContractForm: 'setForm',
      cleanInitialContractForm: 'cleanForm'
    }),
    handleChangeLegalForm(val) {
      this.form = {
        ...form(),
        inn: this.form.inn || ''
      }
      this.form.legalForm = val
    },
    prepareForm() {
      const activeAccount = this.$store.state.Auth.activeAccount
      const accountAlreadyAdded = this.clients.findIndex((client) => client.inn === activeAccount.inn) >= 0

      if (activeAccount && !accountAlreadyAdded) {
        this.form = {
          ...this.form,
          inn: activeAccount.inn,
          name: activeAccount.is_legal 
            ? activeAccount.legal_name 
            : activeAccount.first_name && activeAccount.last_name 
            ? `${activeAccount.first_name} ${activeAccount.last_name}` 
            : activeAccount.name ?? '',
          legalForm: activeAccount.is_legal 
            ? LegalFormsKeys.JURIDICAL_PERSON 
            : LegalFormsKeys.PHYSICAL_PERSON
        }
      }
    },
    handleErrors(err) {
      // const objErrors = JSON.parse(err.data.errors.replace('Mediascout error request:', ''))
      const objErrors = Object.prototype.hasOwnProperty.call(err, 'Mediascout error request') ? JSON.parse(JSON.stringify(err).replace('Mediascout error request:', '')) : JSON.parse(JSON.stringify(err));
      objErrors.ErrorItems.map((errorItem) => {
        if(errorItem.PropertyName) {
          this.errors[errorItem.PropertyName] = [
          ...this.errors[errorItem.PropertyName] || [],
          errorItem.ErrorMessage,
          ]
        } else {
          this.errors.global = [...this.errors.global, errorItem.ErrorMessage]
        }
      })

      this.$notify({
        message: this.$t('new_app.error_message'),
        type: 'error'
      });
    },
    createClient(createMode) {
      return this.$store.dispatch('Clients/createClient', {
        createMode,
        ...this.form
      })
    },
    createInitialClient() {
      this.loading = true 
      this.cleanErrors()

      this.createClient(CreateModesType.INITIAL_CONTRACT_CLIENT)
        .then((client) => {
          this.cleanData()
          this.clickOutsideModal = false

          this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: null })

          this.setInitialContractForm({
            formName: this.getFormName, 
            formValue: client
          })
          
          this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddInitialContract' })
          this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
        }).catch((err) => {
          this.handleErrors(err)
        }).finally(() => {
          this.loading = false
        })
    },
    createDirectClient() {
      this.loading = true 

      this.cleanErrors()
      this.createClient(CreateModesType.DIRECT_CLIENT)
      .then(() => {
        this.cleanData()
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: null })
        this.$notify({
          message: this.$t('new_app.success'),
          type: 'success'
        });
      }).catch((err) => {
        this.handleErrors(err)
      }).finally(() => {
        this.loading = false
      })
    },
    cleanErrors() {
      this.errors = errors()
    },
    cleanData() {
      this.$refs.clientType.selectedValue = null
      this.form = form()
    },
    closeDialog() {
      this.clickOutsideModal = false 

      if (this.isClientForInitialContract) {
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddInitialContract' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      } else {
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
      }

      this.cleanData()
    }
  },
  beforeDestroy() {
    if (this.clickOutsideModal) {
      this.cleanInitialContractForm()
    }
    clearQuery('formName', this.$route.query, this.$router)
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.form-input--full .form-input__label {
    font-size: 1.2rem;
    line-height: 1.4rem;
    top: 0.6rem;
}

.add-client {
  background: #fff;
  color: #000;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__input-icon {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }

  &__group-btns {
    display: flex;
    gap: .8rem;
    flex-direction: column;
  }

  &__btns-wrapper {
    display: flex;
    gap: .8rem;
  }

  &__save-btn {
    width: 17.4rem;
  }

  &__cancel-btn {
    width: 13.7rem;
  }
  
  
  textarea {
    font-family: 'Arial';
  }

  &__title {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.7rem;
    margin-bottom: 6.5rem;
    color: #000;
  }

  &__content {
    padding: 7rem;
    min-height: 100dvh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .selector__content {
    background: none;
    border: 1px solid #BFC2C6;
  }

  .selector__title {
    font-size: 1.6rem;
    color: #878B93;
  }
  &__input {
    z-index: 99;
    
    .selector__content {
      width: 100%;
    }
  }

  .button {
    height: 5rem;
  }

  &__step {
    margin-bottom: 6rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.4rem;
      color: #333333;
      margin-bottom: 2rem;
    }

    &-content {
      .form-input {
        width: 100%;
        max-width: 35rem;

        @include below($sm) {
          max-width: 100%;
        }
      }
    }

    &-error {
      color: $redLight;
      position: absolute;
      left: 6rem;
    }
  }
}

@include below(993px) {
  .add-client__title{
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 30px;
  }

  .add-client__step{
    margin-bottom: 30px;
  }

  .add-client__final,
  .add-client__content{
    padding: 15px;
  }
}

@include below(769px) {
  .add-client__final{
    .button{
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
